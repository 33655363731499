<script context="module" lang="ts">
	type Align = 'start' | 'center' | 'end' | 'stretch';
	type Justify =
		| 'start'
		| 'center'
		| 'end'
		| 'stretch'
		| 'space-between'
		| 'space-around'
		| 'space-evenly';
	type Direction = 'row' | 'row-reverse' | 'column' | 'column-reverse';
	type Wrap = 'nowrap' | 'wrap' | 'wrap-reverse';

	type TProps = {
		fullWidth?: boolean;
		fullHeight?: boolean;
		gap?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
		justify?: Justify | undefined;
		align?: Align | undefined;
		direction?: Direction | undefined;
		wrap?: Wrap | true | undefined;
		alignChildren?: Align | undefined;
		children?: Snippet;
	};
</script>

<script lang="ts">
	import classNames from 'classnames';
	import type { Snippet } from 'svelte';
	const {
		fullHeight,
		fullWidth,
		gap,
		justify,
		align,
		direction,
		wrap,
		alignChildren,
		children,
	}: TProps = $props();

	const classes = $derived(
		classNames('Flex', {
			'Flex--fullWidth': fullWidth,
			'Flex--fullHeight': fullHeight,
			[`Flex--justify-${justify}`]: justify,
			[`Flex--align-${align}`]: align,
			[`Flex--alignChildren-${alignChildren}`]: alignChildren,
			[`Flex--direction-${direction}`]: direction,
			[`Flex--wrap-${wrap === true ? 'wrap' : wrap}`]: wrap,
			[`Flex--gap-${gap}`]: gap,
		}),
	);
</script>

<div class={classes}>
	{#if children}
		{@render children()}
	{/if}
</div>

<style lang="scss">
	.Flex {
		position: relative;
		display: flex;

		&--fullWidth {
			width: 100%;
		}

		&--fullHeight {
			height: 100%;
		}

		&--justify {
			&-start {
				justify-content: start;
			}

			&-center {
				justify-content: center;
			}

			&-end {
				justify-content: end;
			}

			&-stretch {
				justify-content: stretch;
			}

			&-space-between {
				justify-content: space-between;
			}

			&-space-around {
				justify-content: space-around;
			}

			&-space-evenly {
				justify-content: space-evenly;
			}
		}

		&--align {
			&-start {
				align-items: start;
			}

			&-end {
				align-items: end;
			}

			&-center {
				align-items: center;
			}

			&-stretch {
				align-items: stretch;
			}
		}

		&--alignChildren {
			&-start {
				:global(> *) {
					align-self: start;
				}
			}

			&-end {
				:global(> *) {
					align-self: end;
				}
			}

			&-center {
				:global(> *) {
					align-self: center;
				}
			}

			&-stretch {
				:global(> *) {
					align-self: stretch;
				}
			}
		}

		&--direction {
			&-row {
				flex-direction: row;
			}

			&-row-reverse {
				flex-direction: row-reverse;
			}

			&-column {
				flex-direction: column;
			}

			&-column-reverse {
				flex-direction: column-reverse;
			}
		}

		&--wrap {
			&-wrap {
				flex-wrap: wrap;
			}

			&-nowrap {
				flex-wrap: nowrap;
			}

			&-wrap-reverse {
				flex-wrap: wrap-reverse;
			}
		}

		@for $i from 1 through 10 {
			&--gap-#{$i} {
				grid-gap: calc(var(--padding) * $i);
			}
		}
	}
</style>
